








import { Component } from 'nuxt-property-decorator';
import Vue from 'vue';

@Component({
  components: {}
})
export default class BackToTop extends Vue {
  scroll:number = 0;
  isMobile:boolean = false;
  calendly:HTMLElement = null;
  calendlyContent:HTMLElement = null;

  backToTop () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handleScroll () {
    this.scroll = window.scrollY;
    if (process.browser) {
      if (this.scroll >= 1000) {
        this.calendly.classList.add('go-up');
        this.calendlyContent.classList.add('go-up');
      } else if (this.scroll < 1000) {
        this.calendly.classList.remove('go-up');
        this.calendlyContent.classList.remove('go-up');
      }
    }
  }

  mounted () {
    if (process.browser) {
      this.isMobile = window.innerWidth < 900;
      this.calendly = document.getElementById('calendly-icon');
      this.calendlyContent = document.getElementById('calendly-content');
    }
  }

  beforeMount () {
    window.addEventListener('scroll', this.handleScroll);
  }
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
