import { render, staticRenderFns } from "./PageDefault.vue?vue&type=template&id=80051d2e&scoped=true&"
import script from "./PageDefault.vue?vue&type=script&lang=ts&"
export * from "./PageDefault.vue?vue&type=script&lang=ts&"
import style0 from "./PageDefault.vue?vue&type=style&index=0&id=80051d2e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80051d2e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediaHandler: require('/opt/build/repo/components/utilities/media/MediaHandler.vue').default,BackToTop: require('/opt/build/repo/components/elements/BackToTop.vue').default})
