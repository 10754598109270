


























import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import { ContentPageDefault } from '~/types';

@Component
export default class PageDefault extends Vue {
  @Prop() blok!: ContentPageDefault;
  mounted () {
    document.body.classList.remove('page-mode-dark');
  }
}
